import styles from "./Wrapper.module.css";

const Wrapper = ({ children, title = "" }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>{title}</span>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Wrapper;
