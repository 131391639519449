import styles from "./FinancePage.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigaionActions } from "../../MainNavigation/reducers/navigation-slice";
import {
  useGetAccountBalanceQuery,
  useGetZohoStatusQuery,
} from "./apis/financeApi-slice";
import { REDIRECT_URL__ZOHO } from "../../../consts/APIConstants";
import FinancePageOverview from "./FinancePageOverview";
import FinanceDetailsPage from "./FinanceDetailsPage";
import LoadingDocument from "../../UI/LoadingIndicator/LoadingDocument";

const FinancePage = () => {
  const { primaryEntity } = useSelector((state) => state.entity);

  const { isZohoConnected } = useSelector((state) => state.finance);

  const dispatch = useDispatch();

  const {
    isFetching: isLoading,
    isError,
    isSuccess,
  } = useGetZohoStatusQuery({
    entity_id: primaryEntity?.entityId,
    redirect_uri: REDIRECT_URL__ZOHO,
  });

  const { isFetching: isBalanceLoading } = useGetAccountBalanceQuery({
    entity_id: primaryEntity?.entityId,
    redirect_uri: REDIRECT_URL__ZOHO,
  });

  useEffect(() => {
    dispatch(navigaionActions.setNavigationTitle(
      // primaryEntity?.entityName
      "Financials"
    ));
  }, []);

  let content;

  if (isLoading) {
    content = <LoadingDocument className={styles.loading_search} />;
  } else if (isZohoConnected) {
    content = <FinanceDetailsPage isLoading={isBalanceLoading} />;
  } else {
    content = <FinancePageOverview />;
  }

  return <div className={`${styles.wrapper} ${isZohoConnected ? styles.transparent_bg : ""}`}>{content}</div>;
};

export default FinancePage;


