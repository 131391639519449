import { motion } from "framer-motion";
import styles from "./LoadingSpinner.module.css";

const loadingCircleVariants = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  animate: {
    opacity: [1, 0.5, 0.1, 0.5, 1],
    scale: 1,
    transition: {
      opacity: {
        duration: 1.5,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  },
  exit: {
    opacity: 0,
    scale: 0,
  },
};

const LoadingCircle = ({ delay }) => {
  return (
    <motion.span
      className={styles.loadingCircle}
      variants={loadingCircleVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      style={{ animationDelay: `${delay}s` }}
    />
  );
};

const LoadingSpinner = () => {
  return (
    <motion.div
      className={styles.loadingContainer}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {[0, 0.2, 0.4].map((delay, index) => (
        <LoadingCircle key={index} delay={delay} />
      ))}
    </motion.div>
  );
};

export default LoadingSpinner;
