import { styled } from "styled-components";

export const CustomTable = styled.table`
  // margin-top: 1.5rem;
  width: 100%;

  font-family: "SpartanSemiBold";
  font-size: 1.2rem;
  color: #232323;

  // border: 0.1rem solid #e3e3e3;
  // border-radius: 0.8rem;
  border-spacing: 0;
`;

export const TableRow = styled.tr`
height: 4.4rem;
 &:last-child tr {
    border: none;
 }
 &: td:first-child {
    margin-left: 1rem;
 }
 &:td:last-child {
    margin-right: 2rem;
 }
 &:th:last-child {
    margin-right: 2rem;
  }
`;

export const TableHeader = styled.th`
  padding: 0 1rem;

  color: rgba(35, 35, 35, 0.75);
  text-align: left;
  background-color: #F5F8FF;
  font-size: 1rem;
  text-transform: uppercase;

  // border-bottom: 0.1rem solid #e3e3e3;

  &:first-child {
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
  }
  &:last-child {
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
`;

export const TableData = styled.td`
  color: rgba(35, 35, 35, 0.9);
  text-align: left;
  border-bottom: 0.15rem solid #e3e3e3;
  padding: 0 1rem;
  &:first-child {
    // padding-left: 3.6rem;
  }
`;

export const TableFooter = styled.tfoot`
  $:tr:last-child td {
    border-top: 0.15rem solid #e3e3e3;
  }
`;
