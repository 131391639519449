import { useState, useRef } from "react";
import styles from "./TopNavBar.module.css";
import AccountDropDown from "./AccountDropDown";
import Button from "../../UI/Button/Button";
import accountIcon from "/assets/topbar/AccountProfile.svg";
import notificationIcon from "/assets/topbar/NotificationBell.svg";
import { useOutsideClick } from "../../../hooks/use-outsideClick";

const TopNavBar = ({ ...props }) => {
  const ref = useRef();

  const [showAccountDropDown, setShowAccountDropDown] = useState(false);

  useOutsideClick({
    ref,
    handler: () => {
      setShowAccountDropDown(false);
    },
  });

  const handleAccountClick = () => {
    setShowAccountDropDown(!showAccountDropDown);
  };

  const classes = `${styles.topbar}`;

  return (
    <div className={classes}>
      <Button className={styles.action}>
        <img src={notificationIcon} alt="" />
      </Button>

      <Button className={styles.action} onClick={handleAccountClick} ref={ref}>
        <img src={accountIcon} alt="" />
        {showAccountDropDown && <AccountDropDown onLogout={props.onLogout} />}
      </Button>
    </div>
  );
};

export default TopNavBar;
