import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import styles from "./MultiSelectionDropdown.module.css";
import { useOutsideClick } from "../../../hooks/use-outsideClick";
import closeIcon from "/assets/common/Close_Blue.svg";
import Button from "../Button/Button";

const MultiSelectionDropdown = ({
  options,
  value: selectedOptions,
  onSelect,
  className,
  errorMessage,
  showError,
}) => {
  const ref = useRef(null);
  const inputRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          !selectedOptions.includes(option) &&
          (searchQuery === ""
            ? true
            : option.toLowerCase().includes(searchQuery.toLowerCase()))
      ),
    [options, selectedOptions, searchQuery]
  );

  useOutsideClick({
    ref,
    handler: () => setIsOpen(false),
  });

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleOptionClick = (option) => {
    const newSelectedOptions = [...selectedOptions, option];
    onSelect(newSelectedOptions);
    setIsOpen(false);
    setSearchQuery("");
  };

  const handleRemoveOption = (option) => {
    const newSelectedOptions = selectedOptions.filter(
      (selectedOption) => selectedOption !== option
    );
    onSelect(newSelectedOptions);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
      const options = Array.from(
        ref.current.querySelectorAll(`.${styles.dropdown_option}`)
      );
      const currentOptionIndex = options.findIndex(
        (option) => option === document.activeElement
      );
      const nextOptionIndex =
        e.key === "ArrowDown"
          ? (currentcurrentOptionIndexIndex + 1) % options.length
          : (currentOptionIndex - 1 + options.length) % options.length;
      options[nextOptionIndex].focus();
    }
  };

  return (
    <div
      ref={ref}
      className={`${styles.dropdown} ${className}`}
      aria-expanded={isOpen}
    >
      <div
        className={`${styles.select_option_box} ${isOpen ? styles.open : ""}`}
        onClick={() => setIsOpen(true)}
        tabIndex={0}
      >
      </div>

      <div className={styles.selected_options_list}>
        {selectedOptions.map((option) => (
          <div
            key={`seelcted-option-${option}`}
            className={styles.selected_option}
          >
            {option}
            <Button
              className={styles.btn__remove}
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveOption(option);
              }}
            >
              <img src={closeIcon} alt="" />
            </Button>
            {showError && <div className={styles.error}>{errorMessage}</div>}
          </div>
        ))}
      </div>
      {isOpen && (
        <div
          className={styles.dropdown_list_container}
          onKeyDown={handleKeyDown}
        >
          <div className={styles.dropdown_list_content}>
            <input
              ref={inputRef}
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
            />
            <ul className={styles.dropdown_options} role="listbox">
              {filteredOptions.map((option, index) => (
                <li
                  key={`dropdown-option-${option}-${index}`}
                  className={styles.dropdown_option}
                  onClick={() => handleOptionClick(option)}
                  tabIndex={0}
                >
                  {option}
                </li>
              ))}
              {filteredOptions.length === 0 && (
                <li className={styles.dropdown_option_empty}>
                  No countries found
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectionDropdown;
