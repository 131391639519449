import styles from "./EntityListRow.module.css";
import { FlagEmoji } from "react-international-phone";
import { TableRow, TableData } from "../../UI/Table/CustomTable";
import { TBL__ALL_ENITITES } from "../../../consts/TextConstants";
import arrowComplete from "/assets/subscription/links/DoubleRightArrow.svg";
import arrowIcon from "/assets/common/ArrowRight_Grey.svg";
import arrowIconHover from "/assets/common/ArrowRight_Blue.svg";
import Button from "../../UI/Button/Button";
import {
  countriesLookup,
  convertISODateToStr,
} from "../../../utils/common-utils";
import useHover from "../../../hooks/use-hover";

const EntityListRow = ({ entity, row, onRowClick, onCompleteDetailsClick }) => {
  const [isHovered, hoverProps] = useHover();
  return (
    <TableRow
      key={`table-row-${row}`}
      className={`${entity.isBasicComplete ? styles.table_row_completed : ""}`}
      onClick={() => onRowClick(entity)}
      {...hoverProps}
    >
      <TableData key={`table-data-status-${row}`}>
        <div
          className={`${styles.status} ${
            entity.isFullComplete ? styles.completed : styles.pending
          }`}
        ></div>
      </TableData>
      {Object.keys(TBL__ALL_ENITITES).map((key, col) => (
        <TableData key={`table-data-${col}-${row}`}>
          {key === "country" && (
            <div className={styles.img_data_flag}>
              <FlagEmoji
                iso2={countriesLookup(entity[key])?.key}
                className={styles.img_flag}
              />
            </div>
          )}
          {key === "incorporationDate"
            ? convertISODateToStr(entity[key])
            : key !== "country" && entity[key]}
        </TableData>
      ))}
      <TableData key={`table-data-complete-details-${row}`}>
        {!entity.isFullComplete && (
          <Button
            className={styles.btn__complete}
            buttonstyle="yellow-filled"
            onClick={(event) => onCompleteDetailsClick(event, entity)}
          >
            Complete Details
            <img src={arrowComplete} alt="" />
          </Button>
        )}
      </TableData>

      <TableData key={`table-data-arrow-${row}`}>
        <div className={styles.arrow}>
          {entity.isBasicComplete && (
            <img src={isHovered ? arrowIconHover : arrowIcon} alt="" />
          )}
        </div>
      </TableData>
    </TableRow>
  );
};

export default EntityListRow;
