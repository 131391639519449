import styles from "./PrimaryBoardDetails.module.css";
import AddButton from "../../../UI/Button/AddButton";
import addIcon from "/assets/common/Plus_White.svg";
import Table from "../../../UI/Table/Table";
import {
  TBL__HEADERS_LIST_DIRECTORS,
  TBL__HEADERS_LIST_OFFICERS,
  BTN__RETRY,
  MSG__DELETE_CONFIRMATION,
} from "../../../../consts/TextConstants";
import { useDispatch, useSelector } from "react-redux";
import EntityEditPage from "../EntityEdit/EntityEditPage";
import { entityActions } from "../reducers/entity-slice";
import { entityEditForms } from "../../../../consts/Constants";
import { useState } from "react";
import ConfirmationDialog from "../../../UI/Modal/ConfirmationDialog";
import {
  useDeleteBoardMemberMutation,
  useGetBoardMembersQuery,
  useLazyGetBoardMembersQuery,
} from "../apis/entityApi-slice";
import MessageDialog from "../../../UI/Modal/MessageDialog";
import LoadingComponent from "../../../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../../../UI/Error/ErrorComponent";
import { ERROR_MSG__BOARD_DETAILS } from "../../../../consts/ErrorMessages";

const SectionHeader = ({
  title,
  count,
  onClickAdd,
  showAddButton = true,
  ...props
}) => {
  return (
    <div className={`${styles.section_header} ${props.className}`}>
      <div className={styles.section_title}>
        {title}
        <span>{count}</span>
      </div>
      {showAddButton && (
        <AddButton className={styles.btn__add} onClick={onClickAdd} />
      )}
    </div>
  );
};

const PrimaryBoardDetails = () => {
  const {
    boardDirectors,
    boardOfficers,
    selectedEntity,
    availableBoardOfficerTitles,
  } = useSelector((state) => state.entity);
  const [openEditOverlay, setOpenEditOverlay] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false });
  const [error, setError] = useState({ isError: false });

  const dispatch = useDispatch();

  const {
    isFetching: isLoading,
    error: errorBoardMembers,
    isSuccess,
  } = useGetBoardMembersQuery(
    {
      entity_id: selectedEntity?.entityId,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [
    getBoardMembers,
    {
      isFetching: isLoadingOnTrigger,
      error: errorOnTrigger,
      isSuccess: isSuccessOnTrigger,
    },
  ] = useLazyGetBoardMembersQuery();

  const [deleteBoardMember] = useDeleteBoardMemberMutation();

  const handleEditClose = () => {
    setOpenEditOverlay(false);
  };

  const handleClickAdd = (editForm) => {
    setOpenEditOverlay(true);
    dispatch(entityActions.setEditForm(editForm));
  };

  const handleDeleteDirectorClick = (rowList) => {
    setDeleteConfirmation({ show: true, rows: rowList, type: "Director" });
  };
  const handleDeleteOfficerClick = (rowList) => {
    setDeleteConfirmation({ show: true, rows: rowList, type: "Officer" });
  };

  const handleConfirmationClose = () => {
    setDeleteConfirmation({ show: false });
  };

  const handleSuccess = async () => {
    try {
      await getBoardMembers({
        entity_id: selectedEntity?.entityId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const dataToDeleteFrom =
        deleteConfirmation.type == "Director" ? boardDirectors : boardOfficers;

      for (const index of deleteConfirmation.rows) {
        await deleteBoardMember({
          entity_id: selectedEntity?.entityId,
          board_member_name: dataToDeleteFrom[index].name,
          board_member_type: deleteConfirmation.type,
        }).unwrap();
      }
      handleSuccess();

      setDeleteConfirmation({ show: false });
    } catch (err) {
      setDeleteConfirmation({ show: false });
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  if (isLoading || isLoadingOnTrigger) {
    return <LoadingComponent />;
  }

  if (
    (errorBoardMembers && errorBoardMembers?.status !== 404) ||
    (errorOnTrigger && errorOnTrigger?.status !== 404)
  ) {
    return <ErrorComponent errorMessage={ERROR_MSG__BOARD_DETAILS} />;
  }

  return (
    <div className={styles.container}>
      <section className={styles.section_board}>
        <SectionHeader
          title="DIRECTORS"
          count={boardDirectors.length}
          onClickAdd={() => handleClickAdd(entityEditForms.BOARD_DIRECTORS)}
        />
        <Table
          id="table_directors"
          className={styles.table}
          tableHeaders={TBL__HEADERS_LIST_DIRECTORS}
          needOutlineBorder={false}
          minRows={1}
          enableDelete={true}
          tableData={boardDirectors}
          onDelete={handleDeleteDirectorClick}
        />
      </section>

      <section className={styles.section_board}>
        <SectionHeader
          title="OFFICERS"
          count={boardOfficers.length}
          onClickAdd={() => handleClickAdd(entityEditForms.BOARD_OFFICERS)}
          showAddButton={availableBoardOfficerTitles.length > 0}
        />
        <Table
          id="table_officers"
          className={styles.table}
          tableHeaders={TBL__HEADERS_LIST_OFFICERS}
          needOutlineBorder={false}
          minRows={1}
          enableDelete={true}
          tableData={boardOfficers}
          onDelete={handleDeleteOfficerClick}
        />
      </section>

      {openEditOverlay && (
        <EntityEditPage onClose={handleEditClose} onSuccess={handleSuccess} />
      )}

      {deleteConfirmation.show && (
        <ConfirmationDialog
          onClose={handleConfirmationClose}
          onConfirm={handleDeleteConfirm}
          message={MSG__DELETE_CONFIRMATION}
          title="Delete"
        />
      )}
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
    </div>
  );
};

export default PrimaryBoardDetails;
