import { useState } from "react";
import { useDispatch } from "react-redux";
import useInput from "../../../hooks/use-input";
import EditFormActionGroup from "../Edit/EditFormActionGroup";
import EditFormInputs from "../Edit/EditFormInputs";
import EditInputItem from "../Edit/EditInputItem";
import {
  BTN__SUBMIT,
  LBL__GRANT_DATE,
  LBL__NO_OF_UNITS,
  LBL__OPTION_HOLDER_NAME,
  LBL__STRIKE_PRICE,
  LBL__TYPE,
  LIST__STOCK_POOL_OPTIONS,
  BTN__RETRY,
} from "../../../consts/TextConstants";
import { isNotEmpty, isNumeric, isGreaterThanValue } from "../../../utils/validations";
import { convertToISODate } from "../../../utils/common-utils";
import {
  useAddShareHolderMutation,
  useLazyGetShareHoldersQuery,
} from "./apis/capTableApi-slice";
import MessageDialog from "../../UI/Modal/MessageDialog";
import { PAYLD__CREATE_SHARE_HOLDER } from "../../../consts/PayloadConstants";
import { formatRequestData } from "../../../utils/dataFormatter";
import {
  ERROR_MSG__NUMERICAL_VALUES,
  ERROR_MSG__NAME,
  ERROR_MSG__TYPE,
  ERROR_MSG__GREATER_THAN_ZERO,
} from "../../../consts/ErrorMessages";

const AddNewStockOptionPool = ({ stakeType, forYear, entityId, onSuccess, incorporationDate, ...props }) => {
  const dispatch = useDispatch();

  const [grantDate, setGrantDate] = useState(new Date());
  const [poolOptionType, setPoolOptionType] = useState("");

  const [error, setError] = useState({ isError: false });
  const [showPoolOptionTypeError, setShowPoolOptionTypeError] = useState(false);

  const {
    value: optionHolderName,
    isValid: isOptionHolderNameValid,
    showError: showOptionHolderNameError,
    handleChange: handleOptionHolderNameChange,
    handleBlur: handleOptionHolderNameBlur,
    handleFocus: handleOptionHolderNameFocus,
    setShowError: setShowOptionHolderNameError,
  } = useInput(isNotEmpty);

  const {
    value: strikePrice,
    isValid: isStrikePriceValid,
    showError: showStrikePriceError,
    handleChange: handleStrikePriceChange,
    handleBlur: handleStrikePriceBlur,
    handleFocus: handleStrikePriceFocus,
    setShowError: setShowStrikePriceError,
  } = useInput((value) => isNumeric(value));

  const {
    value: noOfUnits,
    isValid: isNoOfUnitsValid,
    showError: showNoOfUnitsError,
    handleChange: handleNoOfUnitsChange,
    handleBlur: handleNoOfUnitsBlur,
    handleFocus: handleNoOfUnitsFocus,
    setShowError: setShowNoOfUnitsError,
  } = useInput((value) => isGreaterThanValue(value, 0) && isNotEmpty(value));

  const [addShareHolder, { isLoading }] = useAddShareHolderMutation();
  // const [getShareHolders] = useLazyGetShareHoldersQuery();

  const validateForm = () => {
    if (
      !isOptionHolderNameValid ||
      !isNoOfUnitsValid ||
      !poolOptionType ||
      !isStrikePriceValid
      ) {
      setShowOptionHolderNameError(!isOptionHolderNameValid);
      setShowNoOfUnitsError(!isNoOfUnitsValid);
      setShowPoolOptionTypeError(!poolOptionType);
      setShowStrikePriceError(!isStrikePriceValid);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const requestData = formatRequestData(
        {
          entityId,
          shareHolder: optionHolderName,
          shareType: poolOptionType,
          issuedDate: convertToISODate(grantDate),
          noOfShares: noOfUnits,
          strikePrice,
          stakeType: "Options",
          forYear: convertToISODate(forYear),
        },
        [],
        PAYLD__CREATE_SHARE_HOLDER
      );
      await addShareHolder(requestData).unwrap();
      // await getShareHolders({
      //   shareholder_as_on: convertToISODate(forYear),
      //   entity_id: entityId,
      // }).unwrap();
      onSuccess();
      props.onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  return (
    <>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
      <EditFormInputs key="add-new-share-holder">
        <EditInputItem
          name="optionHolderName"
          labelTitle={LBL__OPTION_HOLDER_NAME}
          onChange={handleOptionHolderNameChange}
          onBlur={handleOptionHolderNameBlur}
          onFocus={handleOptionHolderNameFocus}
          value={optionHolderName}
          showError={showOptionHolderNameError}
          errorMessage={ERROR_MSG__NAME}
        />
        <EditInputItem
          name="poolOptionType"
          type="dropdown"
          labelTitle={LBL__TYPE}
          onChange={(value) => {
            setPoolOptionType(value);
            setShowPoolOptionTypeError(false);
          }}
          value={poolOptionType}
          options={LIST__STOCK_POOL_OPTIONS}
          showError={showPoolOptionTypeError}
          errorMessage={ERROR_MSG__TYPE}
        />
        <EditInputItem
          name="grantDate"
          type="datepicker"
          labelTitle={LBL__GRANT_DATE}
          onChange={(value) => setGrantDate(value)}
          value={grantDate}
          startDate={new Date(incorporationDate)}
          endDate={new Date()}
        />
        <EditInputItem
          name="noOfUnits"
          labelTitle={LBL__NO_OF_UNITS}
          onChange={handleNoOfUnitsChange}
          onBlur={handleNoOfUnitsBlur}
          onFocus={handleNoOfUnitsFocus}
          value={noOfUnits}
          showError={showNoOfUnitsError}
          errorMessage={ERROR_MSG__GREATER_THAN_ZERO}
        />
        <EditInputItem
          name="strikePrice"
          labelTitle={LBL__STRIKE_PRICE}
          onChange={handleStrikePriceChange}
          onBlur={handleStrikePriceBlur}
          onFocus={handleStrikePriceFocus}
          value={strikePrice}
          showCurrency={true}
          showError={showStrikePriceError}
          errorMessage={ERROR_MSG__GREATER_THAN_ZERO}
        />
      </EditFormInputs>
      <EditFormActionGroup
        primaryTitle={BTN__SUBMIT}
        onClick={handleSubmit}
        // disabled={
        //   !isOptionHolderNameValid || !isNoOfUnitsValid || !poolOptionType
        // }
        isLoading={isLoading}
        disabled={isLoading}
      />
    </>
  );
};

export default AddNewStockOptionPool;
