import styles from "./DropdownHeader.module.css";
import { motion } from "framer-motion";
import lockIcon from "/assets/common/Lock.svg";

const DropdownHeader = ({
  children,
  className,
  isOpen,
  arrow,
  arrowStyle,
  disabled = false,
  ...props
}) => {
  const arrowVariants = { open: { rotate: -180 }, close: { rotate: 0 } };

  const handleClickDropDown = () => {
    if (!disabled) {
      props.onClick();
    }
  };

  return (
    <div
      className={`${styles.dropDownHeader} ${className} ${
        disabled ? styles.disabled : ""
      } ${isOpen ? styles.open : ""}`}
      onClick={handleClickDropDown}
    >
      {props.selected != "" ? (
        <div className={styles.selectedItem}>{props.selected}</div>
      ) : (
        <div className={styles.placeholder}> {props.placeholder}</div>
      )}
      {arrow && (
        <motion.img
          key={`arrow-${isOpen ? "up" : "down"}`}
          variants={arrowVariants}
          initial={isOpen ? "close" : "open"}
          animate={isOpen ? "open" : "close"}
          transition={{ duration: 1, type: "spring" }}
          src={disabled ? lockIcon : arrow}
          alt=""
          className={arrowStyle}
        />
      )}
    </div>
  );
};

export default DropdownHeader;
