import styles from "./ButtonCustom.module.css";
import { motion } from "framer-motion";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

const ButtonCustom = ({ children, className, ...props }) => {
  const classes = `${styles.ui__button} ${className}`;

  return (
    <button className={classes} {...props}>
      {props.isLoading && <LoadingIndicator />}
      {children}
    </button>
  );
};

export default ButtonCustom;
