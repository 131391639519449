import { useEffect, useState } from "react";
import FileInput from "../UI/Input/FileInput";
import TreeViewItem from "./TreeView/TreeViewItem";
import {
  LBL__AMENDMENT_CERTIFICATE,
  LBL__EIN_SS4,
  LBL__BY_LAWS,
  LBL__CAP_TABLE,
  PLACEHOLDER_ATTACH_FILE,
} from "../../consts/TextConstants";
import { documentTypes } from "../../consts/Constants";

const OnboardingCollectDocuments = ({ files = [], ...props }) => {

  const getFileFromArray = (documentType) => {
    const index = files.findIndex(file => file === documentType);
    return index !== -1 ? files[index + 1] : null;
  };

  const [incorporationCertificate, setIncorporationCertificate] =
    useState(getFileFromArray(documentTypes.INCORPORATION_CERTIFICATE));
  const [ein_ss4, setEin_ss4] = useState(getFileFromArray(documentTypes.EIN_LETTER));
  const [capTable, setCapTable] = useState(getFileFromArray(documentTypes.CAP_TABLE));
  const [byLaws, setByLaws] = useState(getFileFromArray(documentTypes.BY_LAWS));
  let fileType = "";

  const handleFileChange = (file, index) => {
    switch (index) {
      case 0:
        setIncorporationCertificate(file);
        fileType = documentTypes.INCORPORATION_CERTIFICATE;
        break;
      case 1:
        setEin_ss4(file);
        fileType = documentTypes.EIN_LETTER;
        break;
      case 2:
        setCapTable(file);
        fileType = documentTypes.CAP_TABLE;
        break;
      case 3:
        setByLaws(file);
        fileType = documentTypes.BY_LAWS;
        break;
    }
    // const isValid = true;
    const isValid = !!(
      incorporationCertificate ||
      ein_ss4 ||
      capTable ||
      byLaws ||
      file
    );
    props.onChange(null, isValid, true, fileType, file);
  };

  useEffect(() => {
    if(files.length > 0) {
      props.onChange(null, true);
    }
  }, []);

  return (
    <>
      <TreeViewItem labelTitle={LBL__AMENDMENT_CERTIFICATE}>
        <FileInput
          placeHolder={PLACEHOLDER_ATTACH_FILE}
          onChange={(file) => handleFileChange(file, 0)}
          defaultFile={incorporationCertificate}
        />
      </TreeViewItem>

      <TreeViewItem labelTitle={LBL__EIN_SS4}>
        <FileInput
          placeHolder={PLACEHOLDER_ATTACH_FILE}
          onChange={(file) => handleFileChange(file, 1)}
          defaultFile={ein_ss4}
        />
      </TreeViewItem>

      <TreeViewItem labelTitle={LBL__CAP_TABLE}>
        <FileInput
          placeHolder={PLACEHOLDER_ATTACH_FILE}
          onChange={(file) => handleFileChange(file, 2)}
          defaultFile={capTable}
        />
      </TreeViewItem>

      <TreeViewItem labelTitle={LBL__BY_LAWS} lastItem={true}>
        <FileInput
          placeHolder={PLACEHOLDER_ATTACH_FILE}
          onChange={(file) => handleFileChange(file, 3)}
          defaultFile={byLaws}
        />
      </TreeViewItem>
    </>
  );
};

export default OnboardingCollectDocuments;
