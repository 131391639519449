import styles from "./Backdrop.module.css";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ReactDOM from "react-dom";

const BackdropView = ({
  children,
  blurValue = "0.4rem",
  className,
  ...props
}) => {
  const backdrop = {
    hidden: { opacity: 0, backdropFilter: "blur(0)" },
    visible: { opacity: 1, backdropFilter: `blur(${blurValue})` },
  };

  return (
    <div
      // variants={backdrop}
      // initial="hidden"
      // animate="visible"
      // exit="hidden"
      // transition={{ duration: 0.3 }}
      className={`${className} ${styles.backdrop}`}
      onClick={props.onClose}
    >
      {children}
    </div>
  );
};

const BackDrop = ({ children, ...props }) => {
  const portalElement = document.getElementById("overlays");
  return (
    <>
      {ReactDOM.createPortal(
        <BackdropView {...props}>{children}</BackdropView>,
        portalElement
      )}
    </>
  );
};

export default BackDrop;
