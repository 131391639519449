import { useEffect, useRef, useState } from "react";
import { isNotEmpty } from "../../utils/validations";
import styles from "./AutoComplete.module.css";
import InputCustom from "./Input/InputCustom";

function getFilteredSuggestions(suggestions, filterVal) {
  return suggestions.filter((item) =>
    item.value.toLowerCase().startsWith(filterVal.toLowerCase())
  );
}

const AutoComplete = ({
  id,
  label,
  suggestions,
  defaultValue = "",
  customFilter = false,
  filteredListFunc,
  showerror = false,
  errormessage,
  inputStyle,
  showListOnTop = false,
  disabled=false,
  ...props
}) => {
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showerrorMessage, setShowErrorMessage] = useState(showerror);
  const ref = useRef();

  useEffect(() => {
    if (defaultValue !== inputValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    setShowAutoComplete(shouldShowAutoComplete(event.target.value));
    props.onSelect(event.target.value);
  };

  const shouldShowAutoComplete = (value) => {
    if (isNotEmpty(value)) {
      const filteredArray = customFilter
        ? filteredListFunc(value)
        : getFilteredSuggestions(suggestions, value);

      setFilteredList(filteredArray);
      setSelectedIndex(0);
      if (filteredArray.length > 0) {
        return true;
      }
    }
    return false;
  };

  const handleFocus = () => {
    setIsInputActive(true);
    setShowAutoComplete(shouldShowAutoComplete(inputValue));
    setShowErrorMessage(false);
  };

  const handleBlur = (event) => {
    setIsInputActive(false);
    if (!isNotEmpty(event.target.value)) {
      setShowErrorMessage(true);
    }
  };

  const handleInputKeyDown = (event) => {
    if (showAutoComplete) {
      let newIndex = selectedIndex;
      if (event.keyCode == 38) {
        newIndex -= 1;
      } else if (event.keyCode == 40) {
        newIndex += 1;
      } else if (event.keyCode == 13) {
        handleSelect(selectedIndex);
      }
      if (newIndex >= filteredList.length) {
        newIndex = 0;
      } else if (newIndex < 0) {
        newIndex = filteredList.length - 1;
      }
      setSelectedIndex(newIndex);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (
        showAutoComplete &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setShowAutoComplete(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isInputActive]);

  const handleSelect = (index) => {
    setInputValue(`${filteredList[index].value}`);
    setShowAutoComplete(false);
    props.onSelect(filteredList[index]);
  };

  return (
    <div className={`${styles.autoCompleteContainer} ${inputStyle}`}>
      <InputCustom
        id={id}
        onChange={handleChange}
        value={inputValue}
        label={label}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleInputKeyDown}
        errormessage={errormessage}
        className={`${styles.input_autocomplete} ${props.autocompleteSize}`}
        disabled={disabled}
      />
      {showAutoComplete && (
        <div className={`${styles.autoCompleteListContainer} ${showListOnTop ? styles.top : styles.bottom}`} ref={ref}>
          <div className={`${styles.autoCompleteList} ${props.listSize}`}>
            {filteredList.map((item, index) => (
              <div
                key={`${index}_${item.key}`}
                className={`${index == selectedIndex ? styles.selected : ""} ${
                  styles.autoCompleteListItem
                }`}
                onClick={() => handleSelect(index)}
              >
                {item.value}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
