import { useState, useRef, useCallback } from "react";
import styles from "./ServiceMoreInfoDropDown.module.css";
import emailIcon from "/assets/common/Email_Black.svg";
import historyIcon from "/assets/common/History.svg";
import { useOutsideClick } from "../../../hooks/use-outsideClick";

const ServiceMoreInfoDropDown = ({ row, column, handleEmail, serviceItem }) => {
  const emailRef = useRef(null);
  const [showMoreInfoDropdown, setShowMoreInfoDropdown] = useState(false);

  useOutsideClick({
    ref: emailRef,
    handler: () => {
      setShowMoreInfoDropdown(false);
    },
  });

  const handleMoreInfoClick = useCallback(() => {
    setShowMoreInfoDropdown(true);
  }, []);

  const handleClickListItem = (event, item) => {
    event.stopPropagation();
    if (item === "email") {
      handleEmail(serviceItem);
      setShowMoreInfoDropdown(false);
    }
  };

  return (
    <div
      key={`more-info-${row}-${column}`}
      className={styles.dots}
      onClick={handleMoreInfoClick}
      ref={emailRef}
    >
      ...
      {showMoreInfoDropdown && (
        <div className={styles.list_container}>
          <ul className={styles.list}>
            <li
              className={styles.list_item}
              onClick={(e) => handleClickListItem(e, "email")}
            >
              <img src={emailIcon} alt="" />
              <span>Email</span>
            </li>
            <li
              className={styles.list_item}
              onClick={(e) => handleClickListItem(e, "history")}
            >
              <img src={historyIcon} alt="" />
              <span>History</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ServiceMoreInfoDropDown;
