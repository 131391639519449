import styles from "./BookKeepingAdminStatus.module.css";
import zohoBooksIcon from "/assets/finance/ZohoBooks.svg";
import adminIcon from "/assets/finance/Admin_Blue.svg";
import disconnectIcon from "/assets/finance/Disconnect.svg";
import disconnectIconHover from "/assets/finance/Disconnect_Red.svg";
import connectedAccountIcon from "/assets/finance/ConnectedAccount.svg";
import closeIcon from "/assets/Close.svg";
import { useState } from "react";
import BackDrop from "../../UI/Modal/Backdrop";
import Button from "../../UI/Button/Button";
import { LBL__NO, LBL__YES } from "../../../consts/TextConstants";
import { useLazyAddAdminUserQuery, useLazyDisconnectAdminQuery, useLazyGetAccountBalanceQuery, useLazyGetZohoStatusQuery } from "./apis/financeApi-slice";
import { useSelector } from "react-redux";
import { REDIRECT_URL__ZOHO } from "../../../consts/APIConstants";
import useHover from "../../../hooks/use-hover";

const ConfirmationDialog = ({
  message,
  showCloseButton = true,
  showAdminIcon = false,
  onClose,
  onConfirm,
  confirmButtonTitle = LBL__YES,
}) => {
  return (
    <>
      <BackDrop onClose={onClose} blurValue="0.2rem" />
      <div className={styles.dialog__admin}>
        {showCloseButton && (
          <button className={styles.btn__close} onClick={onClose}>
            <img src={closeIcon} alt="" />
          </button>
        )}
        {showAdminIcon && (
          <div className={styles.img_admin}>
            <img src={adminIcon} alt="" />
          </div>
        )}
        <div className={styles.description}>{message}</div>
        <div className={styles.dialog__action_items}>
          <Button
            className={`${styles.btn_confirmation}`}
            onClick={onClose}
            buttonstyle="default-blue-outline"
          >
            {LBL__NO}
          </Button>
          <Button
            className={`${styles.btn_confirmation}`}
            onClick={onConfirm}
            buttonstyle="default-blue"
          >
            {confirmButtonTitle}
          </Button>
        </div>
      </div>
    </>
  );
};

const BookKeepingAdminStatus = ({ ...props }) => {
  const [showAdminAccessDialog, setShowAdminAccessDialog] = useState(false);

  const [showDisconnectDialog, setShowDisonnectDialog] = useState(false);

  const { primaryEntity } = useSelector((state) => state.entity);

  const { organizationName } = useSelector((state) => state.finance);

  const [addAdminUser] = useLazyAddAdminUserQuery();

  const [disconnectAdmin] = useLazyDisconnectAdminQuery();

  const [getAccountBalance] = useLazyGetAccountBalanceQuery();

  const [getZohoStatus] = useLazyGetZohoStatusQuery();

  const [isHovered, hoverProps] = useHover();

  const handleAdminAccessConfirmation = async () => {
    try {
      await addAdminUser({
        entity_id: primaryEntity?.entityId,
        redirect_uri: REDIRECT_URL__ZOHO,
      }).unwrap();
    } catch (err) {
      console.log(err);
    } finally {
      setShowAdminAccessDialog(false);
    }
  };

  const handleDisconnectAdminConfirmation = async () => {
    try {
      await disconnectAdmin({
        entity_id: primaryEntity?.entityId,
        redirect_uri: REDIRECT_URL__ZOHO,
      }).unwrap();
      await getAccountBalance({
        entity_id: primaryEntity?.entityId,
        redirect_uri: REDIRECT_URL__ZOHO,
      }).unwrap();
      await getZohoStatus({
        entity_id: primaryEntity?.entityId,
        redirect_uri: REDIRECT_URL__ZOHO,
      }).unwrap();
    } catch (err) {
      console.log(err);
    } finally {
      setShowDisonnectDialog(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content_connection_status}>
      <Button
        className={styles.btn__disconnect}
        // onClick={() => setShowDisonnectDialog(true)}
      >
        <div className={styles.book_keeping_status} />
        <img src={zohoBooksIcon} alt="" />
      </Button>

      <Button
        className={styles.btn__admin}
        // onClick={() => setShowAdminAccessDialog(true)}
        onClick={() => setShowDisonnectDialog(true)}  
        {...hoverProps}
      >
          <img src={isHovered ? disconnectIconHover : disconnectIcon} alt="" />
        </Button>
      </div>
      <div className={styles.connected_account_container}>
        <img src={connectedAccountIcon} alt="" />
        <div className={styles.connected_account}>{organizationName}</div>
      </div>
      {showAdminAccessDialog && (
        <ConfirmationDialog
          message="Give Merze Admin access?"
          showAdminIcon={true}
          onClose={() => setShowAdminAccessDialog(false)}
          onConfirm={handleAdminAccessConfirmation}
        />
      )}

      {showDisconnectDialog && (
        <ConfirmationDialog
          message="Are you sure you want to disconnect books?"
          showCloseButton={false}
          onClose={() => setShowDisonnectDialog(false)}
          onConfirm={handleDisconnectAdminConfirmation}
          confirmButtonTitle="CONFIRM"
        />
      )}
    </div>
  );
};

export default BookKeepingAdminStatus;
