import { useState, useEffect, useCallback, useMemo } from "react";
import styles from "./ServicesPage.module.css";
import AddNewServiceOverlay from "./AddService/AddNewServiceOverlay";
import { useSelector, useDispatch } from "react-redux";
import AddedServicesList from "./AddedServicesList";
import {
  useGetAllUserServicesQuery,
  useGetServicesQuery,
  useLazyGetAllUserServicesQuery,
} from "./apis/serviceApi-slice";
import { navigaionActions } from "../../MainNavigation/reducers/navigation-slice";
import ErrorComponent from "../../UI/Error/ErrorComponent";
import { ERROR_MSG__SERVICES } from "../../../consts/ErrorMessages";
import LoadingList from "../../UI/LoadingIndicator/LoadingList";
import Filter from "../../UI/Filter/Filter";
import SearchBar from "../../UI/Input/SearchBar";
import AddButton from "../../UI/Button/AddButton";
import PageIndicator from "../../UI/PageIndicator/PageIndicator";
import { DEFAULT_PAGE_SIZE } from "../../../consts/AppConstants";
import { debounce } from "lodash";
import useHover from "../../../hooks/use-hover.jsx";
import Wrapper from "../../UI/Wrapper/Wrapper";
const ServicesPage = () => {
  const dispatch = useDispatch();

  const [showAddServiceModal, setShowAddServiceModal] = useState(false);
  const [serviceType, setServiceType] = useState("All");
  const [page, setPage] = useState(1);
  const [showAddServiceButton, setShowAddServiceButton] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { primaryEntity } = useSelector((state) => state.entity); // Entity

  const { services, totalAddedServices } = useSelector(
    (state) => state.services
  );

  const [queryParams, setQueryParams] = useState({
    // entity_id: primaryEntity?.entityId,
    page: page,
    perPage: DEFAULT_PAGE_SIZE,
    type: serviceType !== "All" ? serviceOptions[serviceType] : undefined,
    searchKeyword: searchValue !== "" ? searchValue : undefined,
  });

  const { error, isFetching: isLoading } = useGetAllUserServicesQuery(
    queryParams,
    { refetchOnMountOrArgChange: true }
  );

  const [getAllUserServices] = useLazyGetAllUserServicesQuery();

  const { isSuccess: isSuccessServices } = useGetServicesQuery();

  // const showAddServiceButton = useMemo(() => {
  //   return (
  //     primaryEntity?.isRegistrationEditable ||
  //     primaryEntity?.isIrsEditable ||
  //     primaryEntity?.isRegisterAgentEditable
  //   );
  // }, [primaryEntity]);

  useEffect(() => {
    setShowAddServiceButton(
      !(
        primaryEntity?.isRegistrationEditable ||
        primaryEntity?.isIrsEditable ||
        primaryEntity?.isRegisterAgentEditable
      )
    );
  }, [primaryEntity]);

  const serviceOptions = services.reduce(
    (acc, service) => {
      acc[service.serviceName] = service.serviceId;
      return acc;
    },
    { All: "All" }
  );

  useEffect(() => {
    dispatch(navigaionActions.setNavigationTitle("Services"));
  }, [primaryEntity]);

  const fetchUserServicesParams = useCallback(() => {
    return {
      // entity_id: primaryEntity?.entityId,
      page: page,
      perPage: DEFAULT_PAGE_SIZE,
      service_name:
        serviceType !== "All" ? serviceOptions[serviceType] : undefined,
      searchKeyword: searchValue !== "" ? searchValue : undefined,
    };
  }, [page, serviceType, serviceType, searchValue]);

  const debouncedFetchUserServices = useCallback(
    debounce(async (params) => {
      try {
        await getAllUserServices(params).unwrap();
        setQueryParams(params);
      } catch (error) {
        console.error("Failed to fetch services:", error);
      }
    }, 300),
    [getAllUserServices]
  );

  const handleSuccess = async (params = {}) => {
    setShowAddServiceModal(false);

    try {
      debouncedFetchUserServices({ ...queryParams, ...params });
    } catch (error) {
      console.error("Failed to fetch services:", error);
    }
  };

  useEffect(() => {
    debouncedFetchUserServices(fetchUserServicesParams());
  }, [page, debouncedFetchUserServices]);

  const [isHovered, hoverProps] = useHover();
  // useEffect(() => {
  //   if (page != 1) {
  //     setPage(1);
  //   } else {
  //     const fetchUserServicesParams = fetchUserServicesParams();
  //     debouncedFetchUserServices({ ...fetchUserServicesParams, page: 1 });
  //   }
  // }, [serviceType, searchValue, debouncedFetchUserServices]);

  if (isLoading) return <LoadingList count={4} className={styles.loading} />;

  if (error && error?.status !== 404) {
    return <ErrorComponent errorMessage={ERROR_MSG__SERVICES} />;
  }

  return (
    <Wrapper title="Services">
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.section__filter}>
            {/* {isSuccessServices && (
            <Filter
              labelTitle="Type"
              options={Object.keys(serviceOptions)}
              onSelect={setServiceType}
              dropdownSize={styles.dropdown}
              value={serviceType}
            />
          )}
          <SearchBar
            id="input-search"
            placeholder="Search"
            value={searchValue}
            onChange={setSearchValue}
            inputstyle={styles.searchbar}
          /> */}
          </div>
          <div className={styles.header__content_right} {...hoverProps}>
            {totalAddedServices > 0 && (
              <div
                className={styles.total_services}
              >{`${totalAddedServices} Services`}</div>
            )}
            <AddButton
              className={styles.btn_add_service}
              onClick={() => setShowAddServiceModal(true)}
              // disabled={!showAddServiceButton}
              label="Add Service"
            >
              {/* {!showAddServiceButton && isHovered && (
              <div
                className={styles.add_service_info}
              >{`Please complete the details of ${primaryEntity?.entityName} entity to add a new service`}</div>
            )} */}
            </AddButton>
          </div>
        </header>

        <AddedServicesList
          onSuccess={handleSuccess}
          entityName={primaryEntity?.entityName}
        />
        {totalAddedServices > DEFAULT_PAGE_SIZE && (
          <PageIndicator
            page={page}
            pageSize={DEFAULT_PAGE_SIZE}
            total={totalAddedServices}
            onPageChange={setPage}
          />
        )}
        {showAddServiceModal && (
          <AddNewServiceOverlay
            onClose={() => setShowAddServiceModal(false)}
            setIsModalOpen={() => setShowAddServiceModal(false)}
            onSuccess={handleSuccess}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default ServicesPage;
