import React from "react";
import styles from "./ServiceTypes.module.css";
import rightArrowBlack from "/assets/subscription/links/ArrowRight_Black.svg";
import rightArrowBlue from "/assets/subscription/links/ArrowRight_Blue.svg"; // Blue arrow for selected services

const ServiceTypes = ({ selectedService, onSelect, services }) => {
  return (
    <div className={styles.left_container}>
      <ul>
        {services.length > 0 ? (
          services.map((service) => (
            <li
              key={service.serviceId}
              className={`${styles.clip} ${
                selectedService &&
                service.serviceId === selectedService.serviceId
                  ? styles.selected
                  : ""
              }`}
              onClick={() => onSelect(service)}
            >
              {service.serviceName}
              <img
                src={
                  selectedService &&
                  service.serviceId === selectedService.serviceId
                    ? rightArrowBlue
                    : rightArrowBlack
                }
                alt="right arrow"
                className={styles.right_arrow}
              />
            </li>
          ))
        ) : (
          <li className={styles.no_results}>No services found</li>
        )}
      </ul>
    </div>
  );
};

export default ServiceTypes;
