import Button from "../../UI/Button/Button";
import styles from "./EditFormActionGroup.module.css";

const EditFormActionGroup = ({
  primaryTitle,
  secondaryTitle,
  onClickSecondary,
  ...props
}) => {
  return (
    <div className={styles.form_action}>
      <Button
        layoutId="primary"
        buttonstyle="default-blue"
        className={`${styles.btn__action} ${props.isLoading ? styles.loading : ""}`}
        {...props}
      >
        {primaryTitle}
      </Button>
      {secondaryTitle && (
        <Button
          layoutId="secondary"
          buttonstyle="light-grey-outline-dark-selected"
          className={`${styles.btn__action} ${styles.btn__secondary}`}
          onClick={onClickSecondary}
        >
          {secondaryTitle}
        </Button>
      )}
    </div>
  );
};

export default EditFormActionGroup;
