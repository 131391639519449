import { useDispatch, useSelector } from "react-redux";
import styles from "./ForeignStatesDetails.module.css";
import { EntityDetail } from "./PrimaryEntityDetails";
import {
  TITLE__FORIGN_QUALIFICATION,
  LBL__ADD_FOREIGN_STATE,
  LBL__STATE_ID,
  LBL__BUSINESS_ADDRESS,
  LBL__REGISTRATION_CERTIFICATE,
  LBL__NEXUS_TYPE,
} from "../../../../consts/TextConstants";
import addIcon from "/assets/common/AddIcon_Blue.svg";
import { useState } from "react";
import { entityActions } from "../reducers/entity-slice";
import { entityEditForms } from "../../../../consts/Constants";
import EntityEditPage from "../EntityEdit/EntityEditPage";
import {
  useGetForeignStatesQuery,
  useLazyGetForeignStatesQuery,
} from "../apis/entityApi-slice";
import { getShortFileName } from "../../../../utils/common-utils";
import {
  BASE_URL,
  URL_GET_ENTITY_DOCUMENTS,
} from "../../../../consts/APIConstants";
import moreInfoIcon from "/assets/common/MoreInfo.svg";
import { downloadFile } from "../../../../utils/fileHandler";
import Button from "../../../UI/Button/Button";
import AddButton from "../../../UI/Button/AddButton";
import LoadingComponent from "../../../UI/LoadingIndicator/LoadingComponent";
import MoreInfo from "../../../UI/General/MoreInfo";

const ForeignState = ({ selected = false, stateValue, isAddBtn, ...props }) => {
  return (
    <Button
      className={`${styles.foreign_state} ${
        isAddBtn ? styles.btn_add_initial : ""
      } 
     `}
      buttonstyle={selected ? "default-blue" : "default-blue-translucent"}
      {...props}
    >
      {stateValue}
      {isAddBtn && <img src={addIcon} alt="" />}
    </Button>
  );
};

const ForeignStatesDetails = () => {
  const [openEditOverlay, setOpenEditOverlay] = useState(false);
  const { foreignStates, selectedForeignState, selectedEntity } = useSelector(
    (state) => state.entity
  );
  const {
    isSuccess,
    isFetching: isLoading,
    error,
  } = useGetForeignStatesQuery(
    {
      entity_id: selectedEntity?.entityId,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [
    getForeignState,
    { isFetching: isLoadingonTrigger, error: erroronTrigger },
  ] = useLazyGetForeignStatesQuery();

  const stateInfo = foreignStates ? foreignStates[selectedForeignState] : null;
  const dispatch = useDispatch();

  const handleClickAdd = () => {
    dispatch(entityActions.setEditForm(entityEditForms.FOREIGN));
    setOpenEditOverlay(true);
  };

  const handleClickEdit = (event, index) => {
    if (index == undefined) {
      dispatch(entityActions.setEditForm(entityEditForms.FOREIGN));
      setOpenEditOverlay(true);
    } else {
      dispatch(entityActions.setSelectedForeignState(index));
    }
  };

  const handleEditClose = (event, showLastUpdate = false) => {
    if (showLastUpdate) {
      dispatch(entityActions.setSelectedForeignState(foreignStates.length));
    }
    setOpenEditOverlay(false);
  };

  const handleSuccess = async () => {
    try {
      await getForeignState({
        entity_id: selectedEntity?.entityId,
      }).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadFile = async () => {
    const fileName = stateInfo?.registrationCertificate;
    if (fileName == null || fileName == undefined) {
      return;
    }
    try {
      const fileDownloadURL = BASE_URL + URL_GET_ENTITY_DOCUMENTS;

      const queryparams = `?entity_id=${encodeURIComponent(
        selectedEntity?.entityId
      )}&file_name=${encodeURIComponent(
        fileName
      )}&field_name=registeration_certificate_document`;

      downloadFile(fileDownloadURL, queryparams, fileName);
    } catch (err) {
      console.log(err);
    }
  };

  let nexusTypesContent = (
    <>
      {stateInfo?.nexusTypes.map((item) => (
        <div key={`nexus-type-${item}`} className={styles.nexus_type}>
          {item}
        </div>
      ))}
    </>
  );

  if (isLoading || isLoadingonTrigger) {
    return (
      <LoadingComponent type="default" className={styles.loading_section} />
    );
  }

  if (
    (error && error?.status !== 404) ||
    (erroronTrigger && erroronTrigger?.status !== 404)
  ) {
    return <div />;
  }

  return (
    <section className={styles.foreign_section}>
      <div className={styles.section_header}>
        <div className={styles.section_title}>
          {TITLE__FORIGN_QUALIFICATION}
          <MoreInfo content="Applicable if Physical Office, Payroll or Revenue in the State" />
        </div>

        <AddButton
          className={styles.btn__add}
          onClick={handleClickAdd}
          label="ADD STATE"
        />
      </div>

      <div className={styles.header_List}>
        <div className={styles.foreign_states_list}>
          {foreignStates.map((item, index) => (
            <ForeignState
              key={`${item.state}-${index}`}
              stateValue={item.state}
              selected={index == selectedForeignState}
              onClick={(event) => handleClickEdit(event, index)}
            />
          ))}
        </div>
      </div>

      <div className={styles.section_content}>
        {(selectedEntity?.hasOtherState || foreignStates.length > 0) && (
          <>
            <EntityDetail
              title={LBL__NEXUS_TYPE}
              value={nexusTypesContent || "NA"}
            />

            <EntityDetail
              title={LBL__STATE_ID}
              value={stateInfo?.stateId || "NA"}
            />

            <EntityDetail
              title={LBL__BUSINESS_ADDRESS}
              value={stateInfo?.businessAddress || "NA"}
            />

            <EntityDetail
              title={LBL__REGISTRATION_CERTIFICATE}
              value={`${
                stateInfo?.registrationCertificate ? "Download" : "NA"
              }`}
              type={`${stateInfo?.isRegisteredInState ? "file" : ""}`}
              filename={stateInfo?.registrationCertificate}
              onClick={handleDownloadFile}
            />
          </>
        )}
      </div>

      {openEditOverlay && (
        <EntityEditPage
          onClose={handleEditClose}
          defaultValue={stateInfo}
          className={styles.overlay}
          onSuccess={handleSuccess}
        />
      )}
    </section>
  );
};

export default ForeignStatesDetails;
