import { useState } from "react";
import useInput from "../../../hooks/use-input";
import EditFormActionGroup from "../Edit/EditFormActionGroup";
import EditFormInputs from "../Edit/EditFormInputs";
import EditInputItem from "../Edit/EditInputItem";
import EditAddressInputs from "../Edit/EditAddressInputs";
import {
  BTN__SUBMIT,
  LBL__SHAREHOLDER_NAME,
  LBL__NO_OF_SHARES,
  LBL__ISSUE_DATE,
  LBL__DOCUMENT,
  LBL__PAR_VALUE,
  LBL__SHARE_CLASS,
  BTN__BACK,
  BTN__NEXT,
  BTN__RETRY,
} from "../../../consts/TextConstants";
import {
  isBetweenRange,
  isNotEmpty,
  isNumeric,
} from "../../../utils/validations";
import {
  getCountriesList,
  convertToISODate,
} from "../../../utils/common-utils";
import { useAddShareHolderMutation } from "./apis/capTableApi-slice";
import { PAYLD__CREATE_SHARE_HOLDER } from "../../../consts/PayloadConstants";
import { formatRequestData } from "../../../utils/dataFormatter";
import MessageDialog from "../../UI/Modal/MessageDialog";
import {
  ERROR_MSG__COUNTRY,
  ERROR_MSG__NAME,
  ERROR_MSG__NO_OF_SHARES,
  ERROR_MSG__GREATER_THAN_ZERO,
} from "../../../consts/ErrorMessages";

const countryList = getCountriesList();

const AddNewShareHolder = ({
  stakeType,
  parValue,
  forYear,
  entityId,
  issuedShares,
  nonIssuedShares,
  incorporationDate,
  onSuccess,
  ...props
}) => {
  const [document, setDocument] = useState("");
  const [issuedDate, setIssuedDate] = useState(new Date());
  const [shareClass, setShareClass] = useState("Common");
  const [showAddress, setShowAddress] = useState(false);
  const [citizenship, setCitizenship] = useState("");
  const [shareHolderAddress, setShareHolderAddress] = useState({
    address: "",
    isValid: false,
  });

  const [error, setError] = useState({ isError: false });
  const [showShareHolderAddressError, setShowShareHolderAddressError] =
    useState(false);
  const [showCitizenshipError, setShowCitizenshipError] = useState(false);

  const {
    value: shareHolderName,
    isValid: isShareHolderNameValid,
    showError: showShareHolderNameError,
    handleChange: handleShareHolderNameChange,
    handleBlur: handleShareHolderNameBlur,
    handleFocus: handleShareHolderNameFocus,
    setShowError: setShowShareHolderNameError,
  } = useInput(isNotEmpty);

  const {
    value: noOfShares,
    isValid: isNoOfSharesValid,
    showError: showNoOfSharesError,
    handleChange: handleNoOfSharesChange,
    handleBlur: handleNoOfSharesBlur,
    handleFocus: handleNoOfSharesFocus,
    setShowError: setShowNoOfSharesError,
  } = useInput(
    (value) =>
      isNumeric(value) &&
      isBetweenRange(value, 1, nonIssuedShares) &&
      isNotEmpty(value)
  );

  const [addShareHolder, { isLoading }] = useAddShareHolderMutation();

  const handleChangeDocument = (selectedFile) => {
    setDocument(selectedFile);
  };

  const validateForm = () => {
    if (!isNoOfSharesValid || !isShareHolderNameValid) {
      setShowShareHolderNameError(!isShareHolderNameValid);
      setShowNoOfSharesError(!isNoOfSharesValid);

      return false;
    } else if (showAddress && (!shareHolderAddress.isValid || !citizenship)) {
      setShowShareHolderAddressError(!shareHolderAddress.isValid);

      setShowCitizenshipError(!citizenship);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    if (showAddress) {
      try {
        const data = {
          shareHolder: shareHolderName,
          citizenship,
          shareType: shareClass,
          issuedDate: convertToISODate(issuedDate),
          noOfShares,
          ownership:
            (parseFloat(noOfShares) * 100) /
            (parseFloat(noOfShares) + issuedShares),
          parValue,
          forYear: convertToISODate(forYear),
          stakeType: "Shares",
          street: shareHolderAddress.addressParams.street,
          city: shareHolderAddress.addressParams.city,
          state: shareHolderAddress.addressParams.state,
          country: shareHolderAddress.addressParams.country,
          zip: shareHolderAddress.addressParams.zipCode,
        };
        const requestData = formatRequestData(
          {
            entityId,
            ...data,
          },
          ["share_agreement_document", document],
          PAYLD__CREATE_SHARE_HOLDER
        );
        await addShareHolder(requestData).unwrap();
        onSuccess();
        props.onClose();
      } catch (err) {
        setError({
          isError: true,
          title: "Update error",
          message: err?.data?.message,
        });
      }
    } else {
      setShowAddress(true);
      props.onChangeTitle("Shareholder Current Address");
    }
  };
  const handleSecondaryClick = () => {
    setShowAddress(false);
    props.onChangeTitle("Shareholder");
  };

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  let formContent = (
    <EditFormInputs key="add-new-share-holder">
      <EditInputItem
        name="shareHolderName"
        labelTitle={LBL__SHAREHOLDER_NAME}
        onChange={handleShareHolderNameChange}
        onBlur={handleShareHolderNameBlur}
        onFocus={handleShareHolderNameFocus}
        value={shareHolderName}
        showError={showShareHolderNameError}
        errorMessage={ERROR_MSG__NAME}
      />

      <EditInputItem
        name="noOfShares"
        labelTitle={LBL__NO_OF_SHARES}
        onChange={handleNoOfSharesChange}
        onBlur={handleNoOfSharesBlur}
        onFocus={handleNoOfSharesFocus}
        value={noOfShares}
        showError={showNoOfSharesError}
        errorMessage={noOfShares <= 0 ? ERROR_MSG__GREATER_THAN_ZERO: ERROR_MSG__NO_OF_SHARES }
      />

      <EditInputItem
        name="issuedDate"
        type="datepicker"
        labelTitle={LBL__ISSUE_DATE}
        onChange={(value) => setIssuedDate(value)}
        value={issuedDate}
        startDate={new Date(incorporationDate)}
        endDate={new Date()}
      />

      <EditInputItem
        name="parValue"
        labelTitle={LBL__PAR_VALUE}
        value={`$ ${parValue}`}
        disabled={true}
      />

      <EditInputItem
        name="shareClass"
        type="radio"
        labelTitle={LBL__SHARE_CLASS}
        value={shareClass}
        onChange={(value) => setShareClass(value)}
        options={["Common", "Preferred"]}
      />

      <EditInputItem
        name="document"
        type="file"
        labelTitle={`${LBL__DOCUMENT} (optional)`}
        onChange={handleChangeDocument}
        value={document}
      />
    </EditFormInputs>
  );

  let actionContent = (
    <EditFormActionGroup
      primaryTitle={BTN__NEXT}
      onClick={handleSubmit}
      // disabled={!isNoOfSharesValid || !isShareHolderNameValid}
    />
  );

  if (showAddress) {
    actionContent = (
      <EditFormActionGroup
        primaryTitle={BTN__SUBMIT}
        secondaryTitle={BTN__BACK}
        onClick={handleSubmit}
        onClickSecondary={handleSecondaryClick}
        disabled={isLoading}
        isLoading={isLoading}
      />
    );
    formContent = (
      <EditFormInputs key="add-new-share-holder-address">
        <EditInputItem
          name={`${citizenship}-citizenship`}
          type="autocomplete"
          labelTitle={"Citizenship"}
          value={citizenship || ""}
          defaultValue={""}
          onChange={(item) => {
            setCitizenship(item.value);
            setShowCitizenshipError(false);
          }}
          suggestions={countryList}
          errorMessage={ERROR_MSG__COUNTRY}
          showError={showCitizenshipError}
        />
        <EditAddressInputs
          onChange={(value) => {
            setShareHolderAddress(value);
            if (value.isValid) {
              setShowShareHolderAddressError(false);
            }
          }}
          defaultValue={shareHolderAddress.addressParams}
          name="shareholderAddress"
          showGroupTitle={false}
          showInAddressFormat={false}
          showError={showShareHolderAddressError}
        />
      </EditFormInputs>
    );
  }
  return (
    <>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
      {formContent}
      {actionContent}
    </>
  );
};

export default AddNewShareHolder;
