import styles from "./AddButton.module.css";
import Button from "./Button";
import addIcon from "/assets/common/Plus_Blue.svg";
import addIconHover from "/assets/common/Plus_White.svg";

import useHover from "../../../hooks/use-hover";

const AddButton = ({ children, className, label = "Add", ...props }) => {
  const [isHovered, hoverProps] = useHover();
  return (
    <Button
      className={`${styles.btn__add} ${className}`}
      buttonstyle="default-blue-translucent"
      {...hoverProps}
      {...props}
    >
      <img src={isHovered ? addIconHover : addIcon} alt="" />
      {label}
      {children}
    </Button>
  );
};

export default AddButton;
