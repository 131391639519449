import styles from "./ConfirmationDialog.module.css";
import { motion } from "framer-motion";
import BackDrop from "./Backdrop";
import Button from "../Button/Button";
import { BTN__CONFIRM, BTN__CANCEL } from "../../../consts/TextConstants";

const ConfirmationDialog = ({
  title = "Confirmation",
  message,
  onConfirm,
  onClose,
  ...props
}) => {
  const overlay = {
    hidden: { opacity: 0, y: "40rem" },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <BackDrop
      className={styles.backdrop}
      onClose={onClose}
      blurValue={"0.25rem"}
    >
      <div className={styles.main}>
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.message}>{message}</p>
        </div>
        <div className={styles.btn__group}>
          <Button
            className={styles.ui__btn}
            buttonstyle="grey-outline"
            onClick={onClose}
          >
            {BTN__CANCEL}
          </Button>
          <Button
            className={styles.ui__btn}
            buttonstyle="default-blue"
            onClick={onConfirm}
          >
            {BTN__CONFIRM}
          </Button>
        </div>
      </div>
    </BackDrop>
  );
};

export default ConfirmationDialog;
