import LoadingGradientBox from "./LoadingGradientBox";
import styles from "./LoadingList.module.css";
import styled from "styled-components";

const LoadingListItem = styled.div`
  width: ${(props) => `calc(
        40% / ${props.$total} * ${props.$total - props.$index})`};
  margin-top: ${(props) =>
    props.$index === 0 ? "5rem" : "4rem"}; /* Apply different margins */
`;

const LoadingList = ({ className, count = 2 }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <LoadingGradientBox
        className={styles.loading_top_cont}
        direction="right"
      />
      {[...Array(count)].map((_, index) => (
        <LoadingListItem
          key={index}
          className={styles.loading_pair}
          $total={count}
          $index={index}
        >
          <LoadingGradientBox className={styles.loading} direction="right" />
          <LoadingGradientBox className={styles.loading} direction="right" />
        </LoadingListItem>
      ))}
    </div>
  );
};

export default LoadingList;
