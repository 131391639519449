import styles from "./AccountsSummary.module.css";
import { getImageURL } from "../../MainNavigation/SideBar/NavigationLists";
import {
  useGetRevenueStatementQuery,
  useLazyGetRevenueStatementQuery,
} from "./apis/financeApi-slice";
import {
  addNumberOfDaysToDate,
  getStringFromDateInFormat,
} from "../../../utils/common-utils";
import { REDIRECT_URL__ZOHO } from "../../../consts/APIConstants";
import { useSelector } from "react-redux";
import RevenueStatementForm from "./RevenueStatementForm";
import MoreInfo from "../../UI/General/MoreInfo";
import { useState } from "react";
import { isValid } from "../../../utils/validations";
import ErrorComponent from "../../UI/Error/ErrorComponent";
import { ERROR_MSG__REVENUE_STATEMENT } from "../../../consts/ErrorMessages";
import LoadingGradientBox from "../../UI/LoadingIndicator/LoadingGradientBox";
import useHover from "../../../hooks/use-hover";

const LoadingPlaceholder = () => (
  <div className={styles.loading_container}>
    <LoadingGradientBox className={styles.loading_box} direction="right" />
    <LoadingGradientBox className={styles.loading_box} direction="right" />
    <LoadingGradientBox className={styles.loading_box} direction="right" />
    <LoadingGradientBox className={styles.loading_box} direction="right" />
  </div>
);

const Summary = ({
  title,
  amount,
  percentageValue,
  currencySymbol = "$",
  factor,
  showStatus = true,
  defaultPrefix = "",
  customStyle,
}) => {
  const status = isValid(percentageValue)
    ? percentageValue * factor
    : factor * -1;

  const trendDirection = status * factor < 0 ? "Down" : "Up";
  const trendIconPath = getImageURL(
    `/assets/finance/Trend${trendDirection}_${
      status >= 0 ? "Green" : "Red"
    }.svg`
  );

  const [isHovered, hoverProps] = useHover();
  return (
    <div className={styles.summary_container}>
      <div className={styles.summary_content_container}>
        <div className={`${styles.summary_content} ${customStyle}`}>
          {`${amount < 0 ? "-" : defaultPrefix} ${currencySymbol} ${
            isValid(amount) ? Math.abs(Math.round(amount)).toLocaleString() : ""
          }`}
        </div>
        <div className={styles.summary_title}>{title}</div>
      </div>
      {showStatus && isValid(percentageValue) && (
        <div
          className={`${styles.summary_status} ${
            status < 0 ? styles.red : styles.green
          }`}
          {...hoverProps}
        >
          <img src={trendIconPath} alt="" />
          {`${Math.abs(percentageValue)}%`}
          {isHovered && (
            <div className={styles.moreInfo_box}>
              Compared with preceding period
            </div>
          )}
        </div>
      )}
      {showStatus && !isValid(percentageValue) && (
        <div className={`${styles.summary_status} ${styles.invalid}`}>-</div>
      )}
    </div>
  );
};

const AccountsSummary = () => {
  const today = new Date();

  const [fromDate, setFromDate] = useState(addNumberOfDaysToDate(today, -30));
  const [toDate, setToDate] = useState(new Date());

  const { primaryEntity } = useSelector((state) => state.entity);
  const { revenueStatement, baseCurrencySymbol } = useSelector(
    (state) => state.finance
  );

  const { isFetching: isLoading, isError } = useGetRevenueStatementQuery({
    entity_id: primaryEntity?.entityId,
    from_date: getStringFromDateInFormat(
      addNumberOfDaysToDate(today, -30),
      "YYYY-MM-DD"
    ),
    to_date: getStringFromDateInFormat(new Date(), "YYYY-MM-DD"),
    redirect_uri: REDIRECT_URL__ZOHO,
  });

  const [
    getRevenueStatement,
    { isFetching: isLoadingOnChangePeriod, isError: isErrorOnChangePeriod },
  ] = useLazyGetRevenueStatementQuery();

  const fetchRevenueStatement = async (data) => {
    try {
      await getRevenueStatement(data).unwrap();
      setFromDate(getDateFromString(data.from_date));
      setToDate(getDateFromString(data.to_date));
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading || isLoadingOnChangePeriod) {
    return <LoadingPlaceholder />;
  }

  if (isError || isErrorOnChangePeriod) {
    return <ErrorComponent errorMessage={ERROR_MSG__REVENUE_STATEMENT} />;
  }

  return (
    <div className={styles.container}>
      <RevenueStatementForm
        onSubmit={fetchRevenueStatement}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
      />
      <div className={styles.accounts_summary}>
        <div className={styles.summary_section}>
          <Summary
            title="Income"
            factor={1}
            amount={revenueStatement?.income?.amount}
            percentageValue={revenueStatement?.income?.percenatge}
            currencySymbol={baseCurrencySymbol}
          />
          <div className={styles.line_divider} />
          <Summary
            title="Expenses"
            factor={-1}
            amount={revenueStatement?.expenses?.amount}
            percentageValue={revenueStatement?.expenses?.percenatge}
            currencySymbol={baseCurrencySymbol}
          />
        </div>

        <div className={styles.summary_section}>
          <Summary
            title="Net Gain/Loss"
            factor={1}
            amount={revenueStatement?.netLossProfit?.amount}
            percentageValue={revenueStatement?.netLossProfit?.percenatge}
            currencySymbol={baseCurrencySymbol}
          />
          <div className={styles.line_divider} />

          <Summary
            title="Cash Burn (-) / Cash Addition (+)"
            customContent={true}
            factor={-1}
            amount={revenueStatement?.burnRate?.amount}
            percentageValue={revenueStatement?.burnRate?.percentage}
            currencySymbol={baseCurrencySymbol}
            defaultPrefix="+"
            customStyle={
              revenueStatement?.burnRate?.amount >= 0
                ? styles.cash_burn_green
                : styles.cash_burn_red
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AccountsSummary;
