import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./EntityList.module.css";
import { CustomTable, TableRow, TableHeader } from "../../UI/Table/CustomTable";
import { TBL__ALL_ENITITES } from "../../../consts/TextConstants";
import EntityEditPage from "./EntityEdit/EntityEditPage";
import { entityActions } from "./reducers/entity-slice";
import { entityEditForms } from "../../../consts/Constants";
import EntityListRow from "./EntityListRow";

const EntityList = ({ entities, onSuccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openEditOverlay, setOpenEditOverlay] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const handleRowClick = (entity) => {
    if (entity.isBasicComplete) {
      navigate(`${entity.entityId}`);
    }
  };

  const handleCompleteDetails = (event, entity) => {
    if (!entity.isBasicComplete) {
      event.stopPropagation();
      setOpenEditOverlay(true);
      setSelectedEntity(entity);
      dispatch(entityActions.setEditForm(entityEditForms.REGISTRATION));
    }
  };

  return (
    <>
      <CustomTable className={styles.table}>
        <thead>
          <TableRow key={`table-header-row-${0}`}>
            <TableHeader key={`table-header-status`}></TableHeader>
            {Object.values(TBL__ALL_ENITITES).map((item, col) => (
              <TableHeader key={`table-header-${col}`}>{item}</TableHeader>
            ))}
            <TableHeader key={`table-header-complete-details`}></TableHeader>

            <TableHeader key={`table-header-arrow`}></TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {entities.map((entity, row) => (
            <EntityListRow
              key={`table-row-${row}`}
              entity={entity}
              row={row}
              onRowClick={handleRowClick}
              onCompleteDetailsClick={handleCompleteDetails}
            />
          ))}
        </tbody>
      </CustomTable>
      {openEditOverlay && (
        <EntityEditPage
          className={styles.edit_overlay}
          onClose={() => setOpenEditOverlay(false)}
          entityId={selectedEntity.entityId}
          selectedCountry={selectedEntity.country}
          entityType={selectedEntity.entityType}
          entityName={selectedEntity.entityName}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default EntityList;
