import React from "react";
import styles from "./ServicesAdded.module.css";
import {
  useAddServicesMutation,
  useLazyGetAllUserServicesQuery,
} from "../apis/serviceApi-slice";
import arrowWhite from "/assets/common/ArrowRight_White.svg";
import closeIcon from "/assets/common/Close_Blue.svg";
import { useSelector } from "react-redux";
import { DEFAULT_PAGE_SIZE } from "../../../../consts/AppConstants";
import Button from "../../../UI/Button/Button";

const ServicesAdded = ({ selectedService = {}, onSuccess, onBack }) => {
  const { entities } = useSelector((state) => state.entity);
  const [addService, { isLoading }] = useAddServicesMutation();
  const [getAllServices, { isLoading: isLoadingAllServices }] =
    useLazyGetAllUserServicesQuery();

  const handlePayClick = async () => {
    if (!selectedService || !selectedService.serviceId) return;

    try {
      const serviceInput =
        selectedService.serviceName === "Tax Residency Certificate"
          ? { year: selectedService?.data?.selectedYear || "N/A" }
          : selectedService.serviceName === "Audit & Review Report"
          ? {
              auditFromYear: selectedService?.data?.auditFromYear || "N/A",
              auditFromMonth: selectedService?.data?.auditFromMonth || "N/A",
              auditToYear: selectedService?.data?.auditToYear || "N/A",
              auditToMonth: selectedService?.data?.auditToMonth || "N/A",
            }
          : { year: "N/A" };

      const postData = {
        service_input: serviceInput,
        entity_id: selectedService?.entityId,
      };

      const response = await addService({
        serviceId: selectedService.serviceId,
        ...postData,
      }).unwrap();

      // await getAllServices({ entity_id: primaryEntity?.entityId, page: 1, perPage: DEFAULT_PAGE_SIZE }).unwrap();
      onSuccess(response.data?.subscription_id);
    } catch (error) {
      console.error("Failed to add service:", error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>Service Added</div>
      {/*middle container */}
      <div className={styles.service_box}>
        {/* Entity Name */}
        <p>
          Entity :{" "}
          <span className={styles.entity_name_dynamic}>
            {entities.find((entity) => entity.entityId === selectedService?.entityId)?.entityName}
          </span>
        </p>

        {/* Horizantal line */}
        <hr className={styles.horizantal_line} />

        {/* service name */}
        <div className={styles.s_name_year}>
          <div className={styles.service_cont}>
            <p className={styles.s_name}>{selectedService.serviceName}</p>

            {selectedService.serviceName === "Tax Residency Certificate" && (
              <div className={styles.service_box_year}>
                {selectedService?.data?.selectedYear}
              </div>
            )}
            {selectedService.serviceName === "Audit & Review Report" && (
              <div className={styles.service_box_year}>
                {`${
                  selectedService?.data?.auditFromMonth
                } ‘${selectedService?.data?.auditFromYear
                  ?.toString()
                  .slice(2)} - ${
                  selectedService?.data?.auditToMonth
                } ‘${selectedService?.data?.auditToYear?.toString().slice(2)}`}
              </div>
            )}
          </div>

          <p className={styles.service_box_price}>
            ${selectedService.servicePrice}
          </p>
        </div>
        {/* ends here */}
      </div>

      {/*Remove and Pay Button */}
      <div className={styles.footer}>
        <Button
          className={styles.btn__remove}
          buttonstyle="default-blue-outline"
          onClick={onBack}
        >
          <img src={closeIcon} alt="" />
          REMOVE
        </Button>
        <Button
          className={styles.btn__pay}
          buttonstyle="green-filled"
          onClick={handlePayClick}
          isLoading={isLoading || isLoadingAllServices}
          disabled={isLoading || isLoadingAllServices}
        >
          PAY <img src={arrowWhite} alt="" />
        </Button>
      </div>
    </div>
  );
};

export default ServicesAdded;
