import styles from "./EditFormInputs.module.css";
import { motion } from "framer-motion";
const defaultVariants = {
  moveIn: { x: -400 },
  moveOut: { x: 400 },
  visible: { x: 0 },
};

const EditFormInputs = ({ children, variants = defaultVariants, ...props }) => {
  return <div className={styles.form}>{children}</div>;
};

export default EditFormInputs;
