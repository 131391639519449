import AccountsSummary from "./AccountsSummary";
import Balance from "./Balance";
import styles from "./FinanceDetailsPage.module.css";
import AccountingPermissionOverlay from "./AccountingPermissionOverlay";
import { useState } from "react";
import GetQuoteOverlay from "./GetQuoteOverlay";
import MessageDialog from "../../UI/Modal/MessageDialog";
import { BTN__CLOSE } from "../../../consts/TextConstants";
import { useDispatch, useSelector } from "react-redux";
import { financeActions } from "./reducers/finance-slice";
import BookKeepingAdminStatus from "./BookKeepingAdminStatus";
import LoadingGradientBox from "../../UI/LoadingIndicator/LoadingGradientBox";

const FinanceDetailsPage = ({ isLoading }) => {
  const dispatch = useDispatch();

  const { askAccountingPermission } = useSelector((state) => state.finance);

  const [showGetQuote, setShowGetQuote] = useState(false);

  const [showMessage, setShowMessage] = useState(false);

  const handleAccountingOverlayClose = (confirm) => {
    dispatch(financeActions.setAccountingPermission(false));

    setShowGetQuote(confirm);
  };

  const handleGetQuoteClose = (showConfirmMessage) => {
    setShowGetQuote(false);
    setShowMessage(showConfirmMessage);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Finance</div>
      <div className={styles.content}>
        <div className={`${styles.section__finance_summary} ${styles.section}`}>
          <AccountsSummary />
        </div>
      <BookKeepingAdminStatus />
      <div className={`${styles.section__balance} ${styles.section}`}>
        {isLoading ? <LoadingGradientBox className={styles.loading} direction="right" /> : <Balance />}
        </div>
      </div>
      {askAccountingPermission && (
        <AccountingPermissionOverlay onClose={handleAccountingOverlayClose} />
      )}
      {showGetQuote && <GetQuoteOverlay onClose={handleGetQuoteClose} />}

      {showMessage && (
        <MessageDialog
          isError={false}
          title=""
          message="Thanks for your Interest, Our Sales Team will get back to you with the best Quote."
          buttonTitle={BTN__CLOSE}
          onClose={() => setShowMessage(false)}
        />
      )}
    </div>
  );
};

export default FinanceDetailsPage;
