import { motion, AnimatePresence } from "framer-motion";
import styles from "./MoreInfo.module.css";
import moreInfoIcon from "/assets/common/MoreInfo.svg";
import useHover from "../../../hooks/use-hover";

const MoreInfo = ({
  children,
  content,
  position = "right",
  showMoreInfoIcon = true,
  className,
  descriptionstyle,
  customContainerComponent = false,
  ...props
}) => {
  const [isHovered, hoverProps] = useHover();

  const allProps = {
    ...props,
    ...hoverProps,
  };

  const uniqueId = `tooltip-${content}-${Math.random().toString(36).substr(2, 9)}`;
  return (
    <div className={`${styles.container} ${className}`} {...allProps}>
      {showMoreInfoIcon && <img src={moreInfoIcon} alt="" />}
      {customContainerComponent && children}
      {/* <AnimatePresence mode="wait"> */}
        {isHovered && (
          <motion.div
            key={uniqueId}
            className={`${styles.description} ${styles[position]} ${descriptionstyle}`}
            initial={{ opacity: 0}}
            animate={{ opacity: 1 }}
            // exit={{ opacity: 0}}
            transition={{ duration: 0.1 }}
          >
            {content}
          </motion.div>
        )}
      {/* </AnimatePresence> */}
    </div>
  );
};

export default MoreInfo;
